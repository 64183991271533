<template>
    <!--    <phone-input-->
    <!--        @phone="phone = $event"-->
    <!--        @country="country = $event"-->
    <!--        @phoneData="phoneData = $event"-->
    <!--        class="mt-1 block w-full px-0 app-phone-field"-->
    <!--        :arrow="false"-->
    <!--        defaultCountry="US"-->
    <!--        :allowed="[ 'US', 'CA' ]"-->
    <!--        :value="model?.replace('+', '')"-->
    <!--    />-->

    <div class="flex items-center gap-2">
        <div class="absolute ml-2.5 text-sm mt-1 pointer-events-none">
            {{ countryCode }}
        </div>
        <TextInput
            v-model="localModel"
            class="block w-full pl-8"
            required
            placeholder="(949) 555-1212"
            autocomplete="phone"
            @blur="verify"
            v-bind="$attrs"
        />

    </div>

    <InputError v-if="isInvalid && localModel.length > 0" message="Your phone number is invalid."/>
</template>
<script setup>
import {nextTick, ref, watch} from 'vue';
import TextInput from "@/Components/TextInput.vue";
import {AsYouType, parsePhoneNumber} from 'libphonenumber-js/min'
import InputError from "@/Components/InputError.vue";

const localModel = ref('');
const countryCode = ref('+1');
const phoneData = ref('');
const isInvalid = ref(false);

const model = defineModel()


try {
    const data = parsePhoneNumber(model.value, 'US');
    localModel.value = data.nationalNumber;
} catch (e) {

}


watch(localModel, (value) => {
    try {
        const data = parsePhoneNumber(value, 'US');

        localModel.value = new AsYouType('US').input(value)

        model.value = data.number;
        isInvalid.value = false;
    } catch (e) {
        isInvalid.value = true;
    }
});

const verify = () => {
    try {
        const data = parsePhoneNumber(localModel.value, 'US');

        model.value = data.number;
        isInvalid.value = false;

    } catch (e) {
        isInvalid.value = true;
        nextTick(() => {
            localModel.value = '';
        })

        console.warn(e)
    }
}

</script>
